import { ReactNode } from "react";
import style from "./styles.module.scss";

type InfoBlockType = {
  title: string;
  subtitle: string;
  description: string;
  image: ReactNode;
};

function InfoBlock({ image, subtitle, description, title }: InfoBlockType) {
  return (
    <div className={style.block_info}>
      <div className={style.block_phase}>
        <div className={style.block_img}>{image}</div>
        <h2 className={style.title}>{title}</h2>
      </div>
      <div className={style.block_description}>
        <h3 className={style.subtitle}>{subtitle}</h3>
        <h4 className={style.description}>{description}</h4>
      </div>
    </div>
  );
}

export default InfoBlock;
