// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vdTEnMjGJc_ycyUjz283{border-radius:50px;padding:12px 37px;border:none;color:#fff}.vdTEnMjGJc_ycyUjz283.VSVA2f668sxlOZcnVDrv{background:#32a5e6 !important}.vdTEnMjGJc_ycyUjz283.jlrIBdCwHmikn8EOIsko{background:#41b73f !important}`, "",{"version":3,"sources":["webpack://./component/button/styles.module.scss"],"names":[],"mappings":"AAEA,sBACI,kBAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA,CACA,2CACG,6BAAA,CAEH,2CACI,6BAAA","sourcesContent":["@import '../../assets/styles/variable.scss';\r\n\r\n.button{    \r\n    border-radius: 50px;\r\n    padding: 12px 37px;\r\n    border: none;\r\n    color: #FFFFFF;\r\n    &.primary{\r\n       background: $bacgroundPrimaryButton !important; \r\n    }\r\n    &.secondary{\r\n        background: $bacgroundSecondaryButton !important; \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `vdTEnMjGJc_ycyUjz283`,
	"primary": `VSVA2f668sxlOZcnVDrv`,
	"secondary": `jlrIBdCwHmikn8EOIsko`
};
export default ___CSS_LOADER_EXPORT___;
