import ActivityBlock from "./components/ActivityBlock";
import AreaExpertiseBlock from "./components/AreasExpertiseBlock";
import ArticlesBlock from "./components/ArticlesBlock";
import CarouselBlock from "./components/CarouselBlock";
import CustomerStatisticBlock from "./components/CustomerStatisticBlock";
import CustomersValueBlock from "./components/CustomersValueBlock";

function Home() {
  return (
    <main>
      <CarouselBlock />
      <ActivityBlock />
      <CustomerStatisticBlock />
      <AreaExpertiseBlock />
      <CustomersValueBlock />
      <ArticlesBlock />
    </main>
  );
}

export default Home;
