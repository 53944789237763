import style from "./styles.module.scss";
import Consulting from "../../../../assets/backgroundImage/Consulting.png";
import Button from "../../../button";

function MainBlock() {
  return (
    <section className={style.block_main}>
      <img src={Consulting} alt="Consulting" width={"100%"} />
      <div className={style.block_info}>
        <div>
          <h2 className={style.title}>CONSULTING</h2>
          <div className={style.delimeter}></div>
          <h3 className={style.description}>
            Your Global leader in Data Privacy Guidance
          </h3>
          <Button type="secondary">Contact</Button>
        </div>
      </div>
    </section>
  );
}

export default MainBlock;
