import MainBlock from "./components/MainBlock";
import SymphonySuccessBlock from "./components/SymphonySuccessBlock";
import DescriptionBlock from "./components/DescriptionBlock";

function AboutUs() {
  return (
    <main>
      <MainBlock />
      <DescriptionBlock />
      <SymphonySuccessBlock />
    </main>
  );
}

export default AboutUs;
