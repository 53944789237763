import ApprochBlock from "./components/ApproachBlock";
import ComprehensiveApproachBlock from "./components/ComprehensiveApproachBlock";
import DataPrivacyBlock from "./components/DataPrivacyBlock";
import HolisticDataPrivacyBlock from "./components/HolisticDataPrivacyBlock";
import MainBlock from "./components/MainBlock";
import StrategyBlock from "./components/StrategyBlock";

function Consulting() {
  return (
    <main>
      <MainBlock />
      <DataPrivacyBlock />
      <ApprochBlock />
      <ComprehensiveApproachBlock />
      <HolisticDataPrivacyBlock />
      <StrategyBlock />
    </main>
  );
}

export default Consulting;
