import style from "./styles.module.scss";
import NeuralNexus from "../../assets/images/Neural-Nexus.svg";
import { Link, NavLink } from "react-router-dom";
import Button from "../button";
import { useState } from "react";
import classNames from "classnames";

const LayoutComponent = [
  {
    route: "Home",
    title: "Home",
  },
  {
    route: "Consulting",
    title: "Consulting",
  },
  {
    route: "Marketing",
    title: "Marketing",
  },
  {
    route: "Finance",
    title: "Finance",
  },
  {
    route: "About",
    title: "About us",
  },
];

function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <header className={style.header}>
      <div
        className={style.burgerMenu}
        onClick={() => setOpenMenu((prev) => !prev)}
      >
        {!openMenu ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 15H21V13H3V15ZM3 19H21V17H3V19ZM3 11H21V9H3V11ZM3 5V7H21V5H3Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.92893 6.41421L6.34314 5L19.0711 17.7279L17.6568 19.1421L4.92893 6.41421Z"
              fill="white"
            />
            <path
              d="M19.0711 6.4142L6.34317 19.1421L4.92896 17.7279L17.6569 4.99998L19.0711 6.4142Z"
              fill="white"
            />
          </svg>
        )}
      </div>
      <Link to="Home">
        <img className={style.logo} src={NeuralNexus} alt="Логотип" />
      </Link>
      <nav
        className={classNames(style.nav, {
          [style.isActive]: !openMenu,
        })}
      >
        {LayoutComponent.map(({ route, title }) => {
          return (
            <NavLink
              onClick={() => setOpenMenu((prev) => !prev)}
              key={route}
              to={route}
              className={({ isActive }) =>
                classNames({
                  [style.isActive]: isActive,
                })
              }
            >
              {title}
            </NavLink>
          );
        })}
      </nav>
      <Button>Apply online</Button>
    </header>
  );
}

export default Header;
