import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import PrivateRouter from "./privateRouter";
import Home from "../component/Home";
import Consulting from "../component/Consulting";
import Marketing from "../component/Marketing";
import Finance from "../component/Finance";
import AboutUs from "../component/AboutUs";

function App() {
  return (
    <div
      className={"app"}
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRouter />}>
            <Route index element={<Navigate to="Home" />} />
            <Route path="Home" element={<Home />} />
            <Route path="Consulting" element={<Consulting />} />
            <Route path="Marketing" element={<Marketing />} />
            <Route path="Finance" element={<Finance />} />
            <Route path="About" element={<AboutUs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
