import MainBlock from "./components/MainBlock";
import EmpoweringBlock from "./components/EmpoweringBlock";
import FinancialExpertiseBlock from "./components/FinancialExpertiseBlock";
import FinancialExcellenceBlock from "./components/FinancialExcellenceBlock";
import DescriptionBlock from "./components/DescriptionBlock";
import ElevateBusinessBlock from "./components/ElevateBusinessBlock";

function Finance() {
  return (
    <main>
      <MainBlock />
      <EmpoweringBlock />
      <FinancialExpertiseBlock />
      <FinancialExcellenceBlock />
      <DescriptionBlock />
      <ElevateBusinessBlock />
    </main>
  );
}

export default Finance;
