import style from "./styles.module.scss";

type StatisticBlockType = {
  percent: number;
  title: string;
};

function StatisticBlock({ percent, title }: StatisticBlockType) {
  return (
    <div className={style.block_statistic}>
      <div className={style.percent}>{`${percent}%`}</div>
      <h4 className={style.title}>{title}</h4>
    </div>
  );
}

export default StatisticBlock;
