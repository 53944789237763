import { ReactNode } from "react";
import style from "./styles.module.scss";
import classNames from "classnames";

type InfoBlockType = {
  dataBlocks: {
    ImgBlock: ReactNode;
    title: string;
    description: string;
    secondaryBackground?: boolean;
  }[];
  infoBlocks: {
    imgCategoty: ReactNode;
    title: string;
    description: string;
  };
};

function InfoBlock({ dataBlocks, infoBlocks }: InfoBlockType) {
  return (
    <div className={style.block_data}>
      <div>
        {dataBlocks.map(
          ({ ImgBlock, description, title, secondaryBackground }) => {
            return (
              <div className={style.block_info} key={title}>
                <div
                  className={classNames(style.block_img, {
                    [style.background_secondary]: secondaryBackground,
                  })}
                >
                  {ImgBlock}
                </div>
                <div className={style.block_text}>
                  <h3 className={style.title}>{title}</h3>
                  <h4 className={style.description}>{description}</h4>
                </div>
              </div>
            );
          },
        )}
      </div>
      <div className={style.category}>
        <div className={style.category_img}>
          {infoBlocks.imgCategoty}
          <h3 className={style.category_title}>{infoBlocks.title}</h3>
        </div>
        <h4 className={style.category_description}>{infoBlocks.description}</h4>
      </div>
    </div>
  );
}

export default InfoBlock;
