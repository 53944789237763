import style from "./styles.module.scss";
import Button from "../../../button";
import InfoBlock from "./InfoBlock/InfoBlock";
import moment from "moment";

function ArticlesBlock() {
  return (
    <section className={style.container}>
      <h2 className={style.title}>ARTICLES</h2>
      <div className={style.blocks_description}>
        <InfoBlock
          title="Discover the Advantages and Benefits of Adopting the Data Privacy Framework..."
          description="Today organizations of all sizes are grappling with the increasing complexities and risks associated with data privacy. With stricter regulations and heightened consumer expectations, it is crucial for businesses to prioritize the protection of personal information. The Data Privacy Framework (DPF) program offers a comprehensive solution to address these challenges and provides numerous..."
          date={moment()}
        />
        <InfoBlock
          title="Discover the Advantages and Benefits of Adopting the Data Privacy Framework..."
          description="Today organizations of all sizes are grappling with the increasing complexities and risks associated with data privacy. With stricter regulations and heightened consumer expectations, it is crucial for businesses to prioritize the protection of personal information. The Data Privacy Framework (DPF) program offers a comprehensive solution to address these challenges and provides numerous..."
          date={moment()}
        />
        <InfoBlock
          title="Discover the Advantages and Benefits of Adopting the Data Privacy Framework..."
          description="Today organizations of all sizes are grappling with the increasing complexities and risks associated with data privacy. With stricter regulations and heightened consumer expectations, it is crucial for businesses to prioritize the protection of personal information. The Data Privacy Framework (DPF) program offers a comprehensive solution to address these challenges and provides numerous..."
          date={moment()}
        />
      </div>
      <Button>See more</Button>
    </section>
  );
}

export default ArticlesBlock;
