import style from "./styles.module.scss";
import NeuralNexus from "../../../../../assets/images/Neural-Nexus-Color.svg";
import { ReactNode } from "react";
import Button from "../../../../../component/button";

type VideoBlockType = {
  title: string;
  children: ReactNode;
};

function VideoBlock({ title, children }: VideoBlockType) {
  return (
    <div className={style.block_carusel}>
      <video width="100%" className="video_block" autoPlay loop muted>
        {children}
      </video>
      <div className={style.block_info}>
        <div>
          <div className={style.block_logo}>
            <img src={NeuralNexus} alt="Логотип" />
            <h2 className={style.name_logo}>{"NEURAL NEXUS"}</h2>
          </div>
          <div className={style.delimeter}></div>
          <h3 className={style.title}>{title}</h3>
          <Button type="secondary">Contact</Button>
        </div>
      </div>
    </div>
  );
}

export default VideoBlock;
