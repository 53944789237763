import { Outlet, useLocation } from "react-router-dom";
import style from "./styles.module.scss";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { useLayoutEffect, useRef } from "react";

const PrivateRouter = () => {
  const location = useLocation();
  const scollToRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    scollToRef?.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <div className={style.container}>
      <Header />
      <div ref={scollToRef}>
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default PrivateRouter;
