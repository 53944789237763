import style from "./styles.module.scss";
import Blockchain from "../../../../assets/images/Blockchain.svg";
import Fintech from "../../../../assets/images/Fintech.svg";
import Cybersecurity from "../../../../assets/images/Cybersecurity.svg";

function AreaExpertiseBlock() {
  return (
    <section className={style.container}>
      <h2 className={style.title}>OUR AREAS OF EXPERTISE:</h2>
      <div className={style.container_expertises}>
        <div className={style.expertise}>
          <img src={Blockchain} alt="Blockchain" />
          <h3 className={style.title}>BLOCKCHAIN</h3>
        </div>
        <div className={style.expertise}>
          <img src={Fintech} alt="Fintech" />
          <h3 className={style.title}>FINTECH</h3>
        </div>
        <div className={style.expertise}>
          <img src={Cybersecurity} alt="Cybersecurity" />
          <h3 className={style.title}>CYBERSECURITY</h3>
        </div>
      </div>
    </section>
  );
}

export default AreaExpertiseBlock;
