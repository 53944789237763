import { ReactElement } from "react";
import style from "./styles.module.scss";
import classNames from "classnames";

type ButtonType = {
  type?: "primary" | "secondary";
  children: ReactElement | string;
};
function Button({ type = "primary", children }: ButtonType) {
  return (
    <button
      className={classNames(style.button, {
        [style.primary]: type === "primary",
        [style.secondary]: type === "secondary",
      })}
    >
      {children}
    </button>
  );
}

export default Button;
