import style from "./styles.module.scss";
import Button from "../../../../button";
import Articles from "../../../../../assets/images/Articles.svg";
import Calendar from "../../../../../assets/icons/Calendar.svg";
import moment from "moment";

type InfoBlockType = {
  title: string;
  description: string;
  date: moment.Moment;
};

function InfoBlock({ date, description, title }: InfoBlockType) {
  return (
    <div className={style.block_info}>
      <img src={Articles} alt="Articles" />
      <div className={style.container}>
        <div className={style.content}>
          <h3 className={style.title}>{title}</h3>
          <h4 className={style.description}>{description}</h4>
          <Button>Read more</Button>
        </div>
        <div className={style.footer}>
          <div className={style.date_info}>
            <img src={Calendar} alt="Calendar" />
            <div className={style.date}>{date.format("MMM, DD, YYYY")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoBlock;
