import style from "./styles.module.scss";
import AboutUs from "../../../../assets/backgroundImage/AboutUs.png";
import Button from "../../../button";

function MainBlock() {
  return (
    <section className={style.block_main}>
      <img src={AboutUs} alt="About Us" width={"100%"} />
      <div className={style.block_info}>
        <div>
          <h2 className={style.title}>ABOUT US</h2>
          <div className={style.delimeter}></div>
          <h3 className={style.description}>{"The Neural Nexus Story"}</h3>
          <Button type="secondary">Contact</Button>
        </div>
      </div>
    </section>
  );
}

export default MainBlock;
