import style from "./styles.module.scss";
import NeuralNexus from "../../../../assets/images/Neural-Nexus-Horisontal.svg";
import Consulting from "../../../../assets/images/Consulting.svg";
import Marketing from "../../../../assets/images/Marketing.svg";
import Finance from "../../../../assets/images/Finance.svg";
import Button from "../../../button";
import InfoBlock from "./InfoBlock/InfoBlock";

function ActivityBlock() {
  return (
    <section className={style.block_activity}>
      <img src={NeuralNexus} alt="Logo" />
      <div className={style.blocks_description}>
        <InfoBlock
          title="Consulting"
          description="Protect your business from regulatory fines and issues with our expert consulting services. Our team specializes in GDPR compliance, data privacy, and strategic planning to ensure your operations meet all necessary regulations, safeguarding your business from potential penalties."
        >
          <img src={Consulting} alt="Consulting" />
        </InfoBlock>
        <InfoBlock
          title="Marketing"
          description="Enhance your brand's online presence with Neural Nexus's comprehensive marketing services, including SEO, content marketing, Web Design, social media, and PPC. Connect, engage, and convert your audience."
        >
          <img src={Marketing} alt="Marketing" />
        </InfoBlock>
        <InfoBlock
          title="Finance"
          description="From financial planning and analysis to investment strategies and risk managemetn, we provide the insights and expertise you need to make informed decisions, optimize your financial performance, and secure yor business’s future."
        >
          <img src={Finance} alt="Finance" />
        </InfoBlock>
      </div>
    </section>
  );
}

export default ActivityBlock;
