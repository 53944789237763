import style from "./styles.module.scss";
import Monitoring from "../../../../assets/icons/Monitoring.svg";
import PrivacyProgram from "../../../../assets/icons/PrivacyProgram.svg";
import RiskManagementAndMitigation from "../../../../assets/icons/RiskManagementAndMitigation.svg";
import DataManagement from "../../../../assets/icons/DataManagement.svg";
import DataSubject from "../../../../assets/icons/DataSubject.svg";
import PolicyDevelopment from "../../../../assets/icons/PolicyDevelopment.svg";
import PerfomanceAndImpact from "../../../../assets/icons/PerfomanceAndImpact.svg";
import PrivacyFirstDesign from "../../../../assets/icons/PrivacyFirstDesign.svg";
import AdvancedPrivacyTechnology from "../../../../assets/icons/AdvancedPrivacyTechnology.svg";
import Operational from "../../../../assets/images/Operational.svg";
import Strategic from "../../../../assets/images/Strategic.svg";
import InfoBlock from "./InfoBlock/InfoBlock";

const dataBlockOperation = [
  {
    ImgBlock: <img src={Monitoring} alt="Monitoring" />,
    title: "Continuous Compliance Monitoring:",
    description: "Real-time updates on laws and regulations.",
  },
  {
    ImgBlock: <img src={PrivacyProgram} alt="PrivacyProgram" />,
    title: "Privacy Program Enhancement:",
    description: "Support and improvement for framevorks",
  },
  {
    ImgBlock: (
      <img
        src={RiskManagementAndMitigation}
        alt="RiskManagementAndMitigation"
      />
    ),
    title: "Risk Management & Mitigation:",
    description: "Assess and mitigate data-related risks",
  },
  {
    ImgBlock: <img src={DataManagement} alt="DataManagement" />,
    title: "Data Management Exellence:",
    description: "Classification and analysis for compliance",
  },
  {
    ImgBlock: <img src={DataSubject} alt="DataSubject" />,
    title: "Data Subject Interaction:",
    description: "Manage timely access reguests and responses",
  },
];

const infoBlocksOperation = {
  imgCategoty: <img src={Operational} alt="Operational" />,
  title: "Operational",
  description:
    "At Neural Nexus, our operational strategy ensures compliance with GDPR, ISO standards, and other regulations through continuous monitoring of data privacy laws. We enhance privacy programs, manage data-related risks, and implement best practices for data classification and analysis. Efficient handling of data subject requests ensures timely and accurate responses, improving customer satisfaction.",
};

const dataBlockStrategic = [
  {
    ImgBlock: <img src={PolicyDevelopment} alt="PolicyDevelopment" />,
    title: "Policy Development & Process Implementation:",
    description: "Craft policies and procedures",
    secondaryBackground: true,
  },
  {
    ImgBlock: <img src={PerfomanceAndImpact} alt="PerfomanceAndImpact" />,
    title: "Performance and Impact Assessment:",
    description: "Measure privacy program efficacy",
    secondaryBackground: true,
  },
  {
    ImgBlock: <img src={PrivacyFirstDesign} alt="PrivacyFirstDesign" />,
    title: "Privasy-First Design Principles:",
    description: "Incorporate privacy in all projects.",
    secondaryBackground: true,
  },
  {
    ImgBlock: (
      <img src={AdvancedPrivacyTechnology} alt="AdvancedPrivacyTechnology" />
    ),
    title: "Advanced Privacy Technology Utilization:",
    description: "Use tools for data protection",
    secondaryBackground: true,
  },
];

const infoBlocksStrategic = {
  imgCategoty: <img src={Strategic} alt="Strategic" />,
  title: "Strategic",
  description:
    "Neural Nexus provides strategic solutions to prevent regulatory fines and compliance issues. We develop and implement robust policies tailored to your business needs, ensuring adherence to GDPR, ISO standards, and more. Regular assessments of privacy program performance, integration of privacy-first design principles, and leveraging advanced technologies for data protection help mitigate risks and avoid costly penalties. This proactive strategy secures long-term compliance and resilience for your organization.",
};

function ComprehensiveApproachBlock() {
  return (
    <section className={style.container}>
      <h2 className={style.title}>
        {"OUR COMPREHENSIVE APPROACH\nTO DATA PRIVACY"}
      </h2>
      <div className={style.block_info}>
        <InfoBlock
          dataBlocks={dataBlockOperation}
          infoBlocks={infoBlocksOperation}
        />
        <InfoBlock
          dataBlocks={dataBlockStrategic}
          infoBlocks={infoBlocksStrategic}
        />
      </div>
    </section>
  );
}

export default ComprehensiveApproachBlock;
