import style from "./styles.module.scss";
import Videobackground6 from "../../../../assets/video/Videobackground_6.mp4";
import Button from "../../../button";

function JourneyBlock() {
  return (
    <section className={style.block_video}>
      <video width="100%" className="video_block" autoPlay loop muted>
        <source src={Videobackground6} type="video/mp4" />
      </video>
      <div className={style.block_info}>
        <h2 className={style.main_title}>
          {"EMBARK ON\nYOUR NEURAL NEXUS JOURNEY"}
        </h2>
        <h3 className={style.title}>
          {"YOUR COMPREHENSIVE MARKETING SOLUTION AWAITS"}
        </h3>
        <h4 className={style.description}>
          {
            "Why risk your brand with freelancers or incur the high costs of in-house teams? With Us, you receive expert, consistent, and comprehensive marketing solutions designed to elevate your brand and redefine what's possible. "
          }
        </h4>
        <Button>Schedule a demo</Button>
      </div>
    </section>
  );
}

export default JourneyBlock;
