import style from "./styles.module.scss";
import GenesisAndMission from "../../../../assets/images/GenesisAndMission.svg";
import ExpertiseAndApproach from "../../../../assets/images/ExpertiseAndApproach.svg";
import CultureAndPromise from "../../../../assets/images/CultureAndPromise.svg";
import Button from "../../../button";

const DataBlock = [
  {
    info: [
      {
        title: "OUR GENESIS",
        description: [
          "Neural Nexus emerged from the digital expanse as a beacom of guidance for businesses navigating the multifacated realms of data privacy, marketing innovation, and financial acument.",
          "What began as a cadre of privacy pioneers has burgeoned into a multidisciplinary collective, championing not only data protetction but also strategic marketing and financial foresight.",
        ],
      },
      {
        title: "OUR MISSION",
        description: [
          "We are on a quest to fortify businesses with the acuity to harness the full potential of their data, craft compelling narratives through marketing, and optimize their financial strategies.",
          "Neural Nexus stands as a testament to the power of integrating privacy, marketing brilliance, and financial insight to foster trust, growth, and sustainability.",
        ],
      },
    ],
    image: (
      <img src={GenesisAndMission} alt="Genesis and Mission" width="100%" />
    ),
  },
  {
    info: [
      {
        title: "OUR EXPERTISE",
        description: [
          "Neural Nexus is a conclave of mavens - privacy protectors, marketing maestros, and financial strategists - each a master of their craft.",
          "Our team's rich tapestry of experiences weaves together decades of proficiency, standing at the vanguard od data-driven marketing trends and financial innovation, all while safeguarding the sanctity of personal privacy.",
        ],
      },
      {
        title: "OUR APPROACH",
        description: [
          "We transcend the traditional consultancy paradigm, fusing the narrative finesse of marketing with the precision of financial planning and the rigor of privacy compliance.",
          "Our approach is holistic, crafting a symphony of strategies that resonate with your brand's voice, secure its assets, and captivate your audience.",
        ],
      },
    ],
    image: (
      <img
        src={ExpertiseAndApproach}
        alt="Expertise and Approach"
        width="100%"
      />
    ),
  },
  {
    info: [
      {
        title: "OUR CULTURE",
        description: [
          "Neural Nexus is a conclave of mavens - privacy protectors, marketing maestros, and financial strategists - each a master of their craft.",
          "Our team's rich tapestry of experiences weaves together decades of proficiency, standing at the vanguard od data-driven marketing trends and financial innovation, all while safeguarding the sanctity of personal privacy.",
        ],
      },
      {
        title: "OUR PROMISE",
        description: [
          "We pledge to our partners a voyage of discovery and empowerment.",
          "As custodians of your trust, we navigate the intricacies of regulations, unlock the secrets of market engagement, and fine-tune the levers of financial prowess to elevate your enterprise to unprecedented heights.",
        ],
      },
    ],
    image: (
      <img src={CultureAndPromise} alt="Culture And Promise" width="100%" />
    ),
  },
];

function DescriptionBlock() {
  return (
    <section className={style.container}>
      {DataBlock.map(({ info, image }, index) => {
        return (
          <div key={index} className={style.block_data}>
            <div className={style.blocks_description}>
              {info.map(({ title, description }) => {
                return (
                  <div className={style.block_list} key={title}>
                    <h2 className={style.title}>{title}</h2>
                    <ul className={style.list}>
                      {description.map((text, index) => {
                        return <li key={index}>{text}</li>;
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
            {image}
          </div>
        );
      })}
      <div className={style.block_description}>
        <h2 className={style.title}>OUR INVITATION</h2>
        <h3 className={style.description}>
          Step into the Neural Nexus narrative, where data privacy integrity,
          marketing creativity, and financial sagacity converge.
        </h3>
      </div>
    </section>
  );
}

export default DescriptionBlock;
