import CategoryBlock from "./components/CategoryBlock";
import JourneyBlock from "./components/JourneyBlock";
import MainBlock from "./components/MainBlock";
import PremiumMarketingBlock from "./components/PremiumMarketingBlock";
import ServiceBlock from "./components/ServiceBlock";

function Marketing() {
  return (
    <main>
      <MainBlock />
      <PremiumMarketingBlock />
      <ServiceBlock />
      <CategoryBlock />
      <JourneyBlock />
    </main>
  );
}

export default Marketing;
