import style from "./styles.module.scss";
import Phase1 from "../../../../assets/icons/Phase1.svg";
import Phase2 from "../../../../assets/icons/Phase2.svg";
import Phase3 from "../../../../assets/icons/Phase3.svg";
import Phase4 from "../../../../assets/icons/Phase4.svg";
import InfoBlock from "./InfoBlock/InfoBlock";

const data = [
  {
    image: <img src={Phase1} alt="Phase1" width={"100%"} />,
    title: "Phase 1.\nSTRATEGIC ONBOARDING",
    subtitle: "Initial Engagement",
    description:
      "Kickstart your marketing engine with a deliberate approach that sets the tone for success. At this stage, we focus on calibrating your marketing channels for an optimal start. For $6000 a month, our dedicated team will meticulously craft campaigns designed to turn your first interactions into promising leads, laying the groundwork for a robust sales conversion journey.",
  },
  {
    image: <img src={Phase2} alt="Phase2" width={"100%"} />,
    title: "Phase 2.\nMOMENTUM BUILDING",
    subtitle: "Lead Nurturing",
    description:
      "Gain substantial ground as we nurture your prospects with precision. Witness the power of strategic engagement that moves the needle, transitioning from preliminary interest to concrete leads. Our $6000 subscription model is your gateway to a marketing apparatus that works tirelessly to cultivate and warm your leads, preparing them for the pivotal conversion moment.",
  },
  {
    image: <img src={Phase3} alt="Phase3" width={"100%"} />,
    title: "Phase 3.\nCONVERSION CULTIVATION",
    subtitle: "Conversion Readiness",
    description:
      "Thrive in the art of conversion as we hone in on ready-to-convert leads. At this pivotal stage, your marketing funnel is optimized to transform interest into action. Our expert team at Neural Nexus will deploy targeted campaigns and conversion tactics as part of your subscription, ensuring that when a lead shows signs of readiness, the path to conversion is irresistible.",
  },
  {
    image: <img src={Phase4} alt="Phase4" width={"100%"} />,
    title: "Phase 4.\nREVENUE REALIZATION",
    subtitle: "Closed-Won Deals",
    description:
      "Achieve the pinnacle of your sales process as prospects become profitable conversions. Our comprehensive marketing subscription model is designed to not only reach but surpass the 3% conversion ceiling. We'll scale your campaigns, refine your targeting, and employ advanced analytics to continually improve performance, ensuring your investment translates into tangible, recurring revenue.",
  },
];

function CategoryBlock() {
  return (
    <section className={style.container}>
      {data.map((props) => {
        return <InfoBlock key={props.title} {...props} />;
      })}
    </section>
  );
}

export default CategoryBlock;
