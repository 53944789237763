import style from "./styles.module.scss";
import FinancialResilence from "../../../../assets/images/FinancialResilence.svg";
import FinancialUsertainties from "../../../../assets/images/FinancialUsertainties.svg";
import RootingBusiness from "../../../../assets/images/RootingBusiness.svg";
import Strategizing from "../../../../assets/images/Strategizing.svg";
import classNames from "classnames";

const DataBlock = [
  {
    title: "Building\nFinancial Resilience",
    description:
      "In turbulent times, financial resilience is your anchor. With Neural Nexus, you're not just surviving; you're charting a course for enduring prosperity.",
    image: <img src={FinancialResilence} alt="FinancialResilence" />,
  },
  {
    title: "Mastering\nFinancial Uncertainties",
    description:
      "Financial Crisis Management: Ready your business with the expertise of Neural Nexus to turn financial upheavals into strategic victories, securing a foothold even in volatile markets.",
    image: <img src={FinancialUsertainties} alt="FinancialUsertainties" />,
  },
  {
    title: "Rooting Your Business\nin Sustainability",
    description:
      "Sustainable Financial Practices: We intertwine sustainability with profitability, crafting financial practices that ensure your business thrives today and is revered tomorrow.",
    image: <img src={RootingBusiness} alt="RootingBusiness" />,
  },
  {
    title: "Strategizing\nfor Prosperous Futures",
    description:
      "Capital Management: Sculpt your business's financial core to support expansion, manage risks, and maintain fluidity, empowering you to seize opportunities with agility.",
    image: <img src={Strategizing} alt="Strategizing" />,
  },
];

function DescriptionBlock() {
  return (
    <section className={style.container}>
      {DataBlock.map(({ title, description, image }, index) => {
        return (
          <div
            key={title}
            className={classNames(style.block_data, {
              [style.left]: index % 2 === 0,
              [style.right]: index % 2 !== 0,
            })}
          >
            <div className={style.block_title}>
              <h2 className={style.title}>{title}</h2>
              <h3 className={style.description}>{description}</h3>
            </div>
            {image}
          </div>
        );
      })}
    </section>
  );
}

export default DescriptionBlock;
