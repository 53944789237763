import style from "./styles.module.scss";
import InfoBlock from "./InfoBlock/InfoBlock";
import StarTeam from "../../../../assets/images/StarTeam.svg";
import AdaptiveCampaings from "../../../../assets/images/AdaptiveCampaings.svg";
import TransparentReporting from "../../../../assets/images/TransparentReporting.svg";

function ServiceBlock() {
  return (
    <section className={style.container}>
      <div className={style.block_title}>
        <h2 className={style.title}>MARKETING SERVICE</h2>
        <h3 className={style.subtitle}>Your Subscription Advantages</h3>
      </div>
      <div className={style.blocks_description}>
        <InfoBlock
          title={"ALL-STAR\nTEAM"}
          description="Access to a dedicated team of marketing professionals, including strategists, content creators, web designers and data analysts"
        >
          <img src={StarTeam} alt="StarTeam" />
        </InfoBlock>
        <InfoBlock
          title={"ADAPTIVE\nCAMPAIGNS"}
          description="Agile marketing strategies that evolve with market trends and consumer behavior"
        >
          <img src={AdaptiveCampaings} alt="AdaptiveCampaings" />
        </InfoBlock>
        <InfoBlock
          title={"TRANSPARENT\nREPORTING"}
          description="Monthly insights and detailed reports to track progress and ROMI"
        >
          <img src={TransparentReporting} alt="TransparentReporting" />
        </InfoBlock>
      </div>
    </section>
  );
}

export default ServiceBlock;
