import style from "./styles.module.scss";
import CompuretPrivacy from "../../../../assets/images/CompuretPrivacy.svg";

const categoryData = [
  "Navigating the labyrinth\nof worldwide data privacy laws",
  "Resolving resource and\nexpertise gaps",
  "Ensuring continuous regulatory\ncompliance",
  "Making privacy operations\nactionable",
  "Deploying advanced privacy tools\nand providing ongoing support",
];

function HolisticDataPrivacyBlock() {
  return (
    <section className={style.container}>
      <div className={style.block_image}>
        <img src={CompuretPrivacy} alt="CompuretPrivacy" />
      </div>
      <h2 className={style.title}>{"OUR HOLISTIC METHOD\nFOR DATA PRIVACY"}</h2>
      <div className={style.description_block}>
        <h4 className={style.description}>
          {
            "As the fabric of data privacy regulations continuously weaves intricate patterns across the globe,\nNeural Nexus stands at the forefront, guiding businesses through the evolving tapestry of compliance.\nThe landscape of data privacy is as dynamic as it is complex, with laws like the EU's GDPR and California's\nCCPA only representing the start of an extensive regulatory evolution. New rules emerge, and existing ones\nevolve, underscoring the fluidity of the regulatory environment."
          }
        </h4>
        <h4 className={style.description}>
          {
            "Faced with this persistent change, Neural Nexus embraces a comprehensive and agile framework\nthat transcends the limitations of any single regulation. We proactively address the multifaceted\nchallenges of data privacy, including:"
          }
        </h4>
      </div>
      <div className={style.block_category}>
        {categoryData.map((title) => {
          return (
            <div className={style.category} key={title}>
              <h3 className={style.title}>{title}</h3>
            </div>
          );
        })}
      </div>
      <div className={style.description_block}>
        <h4 className={style.description}>
          {
            "By anticipating legal shifts and not just reacting to them, Neural Nexus lays down a solid\nand adaptable privacy infrastructure that incorporates core principles, educates stakeholders,\nand crafts a flexible governance model for navigating change. This foundation positions businesses\nto face the unpredictable tides of privacy regulations with confidence and resilience."
          }
        </h4>
      </div>
    </section>
  );
}

export default HolisticDataPrivacyBlock;
