import style from "./styles.module.scss";
import Videobackground5 from "../../../../assets/video/Videobackground_5.mp4";
import Button from "../../../button";

function StrategyBlock() {
  return (
    <section className={style.block_video}>
      <video width="100%" className="video_block" autoPlay loop muted>
        <source src={Videobackground5} type="video/mp4" />
      </video>
      <div className={style.block_info}>
        <h2 className={style.main_title}>
          {"READY TO FORTIFY YOUR\nDATA PRIVACY STRATEGY?"}
        </h2>
        <h3 className={style.title}>{"CONNECT WITH NEURAL NEXUS TODAY."}</h3>
        <h4 className={style.main_description}>
          {
            "Join forces with Neural Nexus and transform your approach to data privacy. Our team of experts is ready to empower your organization with cutting-edge privacy solutions that not only comply with today's standards but are robust enough to adapt to tomorrow's challenges."
          }
        </h4>
        <h4 className={style.description}>
          {
            "Don't wait for regulatory demands to dictate your next move. Be proactive. Be prepared."
          }
        </h4>
        <Button>Apply online</Button>
        <div className={style.block_position}>
          <h3>EXPLORE OUR SERVICES</h3>
          <div className={style.delimeter}></div>
          <h3>SCHEDULE A CONSULTATION</h3>
          <div className={style.delimeter}></div>
          <h3>LEARN MORE</h3>
        </div>
      </div>
    </section>
  );
}

export default StrategyBlock;
