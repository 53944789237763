import style from "./styles.module.scss";
import InfoBlock from "./InfoBlock/InfoBlock";
import FinancialAnalytics from "../../../../assets/icons/FinancialAnalytics.svg";
import RiskManagement from "../../../../assets/icons/RiskManagement.svg";
import CostOptimization from "../../../../assets/icons/CostOptimization.svg";

function FinancialExpertiseBlock() {
  return (
    <section className={style.container}>
      <h2 className={style.title}>OUR FINANCIAL EXPERTISE</h2>
      <div className={style.blocks_description}>
        <InfoBlock
          title={"FINANCIAL ANALYTICS\nAND REPORTING:"}
          description="Transform data into actionable insights with our advanced analytics and comprehensive reporting"
        >
          <img src={FinancialAnalytics} alt="FinancialAnalytics" />
        </InfoBlock>
        <InfoBlock
          title={"RISK MANAGEMENT\nAND COMPLIANCE:"}
          description="Navigate the complexities of financial regulations with our risk management solutions"
        >
          <img src={RiskManagement} alt="RiskManagement" />
        </InfoBlock>
        <InfoBlock
          title={"COST OPTIMIZATION"}
          description="Identify and implement cost-saving measures without compromising on quality or performance"
        >
          <img src={CostOptimization} alt="CostOptimization" />
        </InfoBlock>
      </div>
    </section>
  );
}

export default FinancialExpertiseBlock;
