import style from "./styles.module.scss";
import FinancialAudits from "../../../../assets/images/FinancialAudits.svg";
import FinancialModeling from "../../../../assets/images/FinancialModeling.svg";
import EnhancementTechniques from "../../../../assets/images/EnhancementTechniques.svg";
import FinancialSolutions from "../../../../assets/images/FinancialSolutions.svg";

const DataBlock = [
  {
    title: "Financial Audits",
    description:
      "We conduct deep-dive analyses to uncover opportunities and strengthen your financial strategies",
    image: <img src={FinancialAudits} alt="FinancialAudits" />,
  },
  {
    title: "Financial Modeling",
    description:
      "Craft custom financial models that reflect the unique dynamics of your business",
    image: <img src={FinancialModeling} alt="FinancialModeling" />,
  },
  {
    title: "Revenue Enhancement Techniques",
    description:
      "Leverage our expertise to explore new revenue streams and optimize existing ones",
    image: <img src={EnhancementTechniques} alt="EnhancementTechniques" />,
  },
  {
    title: "Financial Solutions",
    description:
      "Utilize cutting-edge tools to streamline your financial operations and enhance decision-making",
    image: <img src={FinancialSolutions} alt="FinancialSolutions" />,
  },
];

function FinancialExcellenceBlock() {
  return (
    <section className={style.container}>
      <h2 className={style.title}>{"OUR APPROACH TO\nFINANCIAL EXCELLENCE"}</h2>
      <div className={style.blocks_info}>
        {DataBlock.map(({ title, description, image }) => {
          return (
            <div className={style.block_data}>
              <div className={style.block_title}>
                <h3 className={style.title}>{title}</h3>
                <h4 className={style.description}>{description}</h4>
              </div>
              {image}
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default FinancialExcellenceBlock;
