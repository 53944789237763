import { ReactNode } from "react";
import style from "./styles.module.scss";

type InfoBlockType = {
  title: string;
  description: string;
  children: ReactNode;
};

function InfoBlock({ children, description, title }: InfoBlockType) {
  return (
    <div className={style.block_info}>
      <div className={style.block_img}>
        {children}
        <h3 className={style.title}>{title.toUpperCase()}</h3>
      </div>
      <h4 className={style.description}>{description}</h4>
    </div>
  );
}

export default InfoBlock;
