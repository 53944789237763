import VideoBackground1 from "../../../../assets/video/Videobackground_1.mp4";
import VideoBackground2 from "../../../../assets/video/Videobackground_2.mp4";
import VideoBackground3 from "../../../../assets/video/Videobackground_3.mp4";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import VideoBlock from "./VideoBlock/VideoBlock";

function CarouselBlock() {
  return (
    <section>
      <Carousel showThumbs={false} showStatus={false}>
        <VideoBlock
          title={
            "Professional IT and Digital Technology Services"
          }
        >
          <source src={VideoBackground1} type="video/mp4" />
        </VideoBlock>
        <VideoBlock title={"Data protection and GDPR Compliance Services"}>
          <source src={VideoBackground2} type="video/mp4" />
        </VideoBlock>
        <VideoBlock title={"Cutting-Edge Technology Solutions for Business Growth"}>
          <source src={VideoBackground3} type="video/mp4" />
        </VideoBlock>
      </Carousel>
    </section>
  );
}

export default CarouselBlock;
