import style from "./styles.module.scss";
import Videobackground8 from "../../../../assets/video/Videobackground_8.mp4";
import Button from "../../../button";

function SymphonySuccessBlock() {
  return (
    <section className={style.block_video}>
      <video width="100%" className="video_block" autoPlay loop muted>
        <source src={Videobackground8} type="video/mp4" />
      </video>
      <div className={style.block_info}>
        <h2 className={style.description}>
          {
            "EMBARK ON A JOURNEY WHERE PRIVACY,MARKETING, AND FINANCE ARE NOT JUST ALIGNED BUT ARE IN CONCERT, CREATING A SYMPHONY OF SUCCESS."
          }
        </h2>
        <Button>Apply online</Button>
        <div className={style.block_position}>
          <h3>EXPLORE OUR SERVICES</h3>
          <div className={style.delimeter}></div>
          <h3>SCHEDULE A CONSULTATION</h3>
          <div className={style.delimeter}></div>
          <h3>LEARN MORE</h3>
        </div>
      </div>
    </section>
  );
}

export default SymphonySuccessBlock;
