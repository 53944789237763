import style from "./styles.module.scss";
import StatisticBlock from "./StatisticBlock/StatisticBlock";

function CustomerStatisticBlock() {
  return (
    <section className={style.block_customer}>
      <h3 className={style.block_title}>Customer statistics</h3>
      <div className={style.block_percent}>
        <StatisticBlock percent={92} title="Legal Entities" />
        <StatisticBlock percent={8} title="Individuals" />
      </div>
      <div className={style.block_percent}>
        <StatisticBlock
          percent={54}
          title="Legal Entities from the EU"
        />
        <StatisticBlock percent={34} title="Legal entities of CIS countries" />
        <StatisticBlock percent={12} title="USA Residents" />
      </div>
    </section>
  );
}

export default CustomerStatisticBlock;
