import style from "./styles.module.scss";
import X from "../../assets/icons/X.svg";
import In from "../../assets/icons/In.svg";
import NeuralNexus from "../../assets/images/Neural-Nexus-Horisontal-White.svg";
import { Link } from "react-router-dom";
import Button from "../button";

function Footer() {
  return (
    <footer className={style.footer}>
      <div className={style.content}>
        <div>
          At Neural Nexus, our mission is to help companies excel in data
          protection, marketing, finance, and consulting. We ensure adherence to
          global data protection standards like GDPR, CCPA, and PIPEDA, and
          support compliance with the EU-U.S. Data Privacy Frameworks.
          Additionally, we offer comprehensive marketing solutions, strategic
          financial planning, and expert consulting services. If you’re looking
          to build a compliance framework or enhance your business operations,
          we can assist. Contact us today to discover how Neural Nexus can
          support you.
        </div>
        <div className={style.block_info}>
          <div className={style.block_teg}>
            <div className={style.block_link}>
              <div className={style.info_link}>
                <div>SERVICES</div>
                <div className={style.link}>
                  <Link to="Consulting">CONSULTING</Link>
                  <Link to="Marketing">MARKETING</Link>
                  <Link to="Finance">FINANCE</Link>
                </div>
              </div>
              <div className={style.info_link}>
                <div>COMPANY</div>
                <div className={style.link}>
                  <Link to="About">ABOUT</Link>
                  <div>PRIVACY GUIDANCE</div>
                  <div>CONTACT</div>
                </div>
              </div>
            </div>
            <div className={style.block_contact}>
              <div>CONTACT</div>
              <div className={style.contact}>
                <Button>Apply online</Button>
                <div>FOLLOW US</div>
                <div className={style.block_img}>
                  <img src={X} />
                  <img src={In} />
                </div>
              </div>
            </div>
          </div>
          <div className={style.block_logo}>
            <img src={NeuralNexus} alt="NeuralNexus" />
            <div>{"NEURAL\n NEXUS"}</div>
          </div>
        </div>
      </div>
      <div className={style.rights}>
        <div>© 2024, NEURAL NEXUS. ALL RIGHTS RESERVED</div>
        <div className={style.policy}>
          <div>PRIVACY POLICY</div>
          <div>COOKIE POLICY</div>
          <div>TERMS & CONDITIONS</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
