import style from "./styles.module.scss";
import Computer from "../../../../assets/images/Computer.svg";

function DataPrivacyBlock() {
  return (
    <section className={style.container}>
      <div className={style.block_image}>
        <img src={Computer} alt="Computer" />
      </div>
      <h2 className={style.title}>{"Data Privacy Services\nMade Simple"}</h2>
      <h3 className={style.description}>
        {
          "At Neural Nexus, we offer a suite of consulting services tailored to fortify your business’s core functions while ensuring compliance and operational excellence. Our expertise extends to rigorous audits, crafting robust privacy policies, and sculpting clear terms and conditions to protect your business and your customers."
        }
      </h3>
    </section>
  );
}

export default DataPrivacyBlock;
