import style from "./styles.module.scss";
import ComputerMarketing from "../../../../assets/images/ComputerMarketing.svg";

function PremiumMarketingBlock() {
  return (
    <section className={style.container}>
      <div className={style.block_image}>
        <img src={ComputerMarketing} alt="ComputerMarketing" />
      </div>
      <h2 className={style.title}>{"Premium Marketing\nat Unmatched Value"}</h2>
      <div className={style.block_description}>
        <h3 className={style.description}>
          {
            "Why hire a single marketer when you can have a team of experts for less?"
          }
        </h3>
        <h3 className={style.description}>
          {
            "Starting at $6000 per month, Neural Nexus offers you a subscription to a full-service marketing department. We provide an all-inclusive suite of marketing services designed to scale with your business and deliver measurable results."
          }
        </h3>
      </div>
    </section>
  );
}

export default PremiumMarketingBlock;
