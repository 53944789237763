import style from "./styles.module.scss";
import VideoBackground4 from "../../../../assets/video/Videobackground_4.mp4";
import Button from "../../../button";

function CustomersValueBlock() {
  return (
    <section className={style.block_video}>
      <video width="100%" className="video_block" autoPlay loop muted>
        <source src={VideoBackground4} type="video/mp4" />
      </video>
      <div className={style.block_info}>
        <div className={style.block_title}>
          <h2 className={style.title}>
            {"DELIVER EXCEPTIONAL VALUE\nTO YOUR CUSTOMERS"}
          </h2>
        </div>
        <div className={style.block_description}>
          <h4 className={style.title}>
            {
              "We’re not just consultants: we’re your allies in creating a secure, compliant, and competitive business environment"
            }
          </h4>
          <Button type="secondary">Our services</Button>
        </div>
      </div>
    </section>
  );
}

export default CustomersValueBlock;
