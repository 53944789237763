import style from "./styles.module.scss";
import Marketing from "../../../../assets/backgroundImage/Marketing.png";
import Button from "../../../button";

function MainBlock() {
  return (
    <section className={style.block_main}>
      <img src={Marketing} alt="Marketing" width={"100%"} />
      <div className={style.block_info}>
        <div>
          <h2 className={style.title}>MARKETING</h2>
          <div className={style.delimeter}></div>
          <h3 className={style.description}>
            {"Your Best Marketing Solution\nStarting at $6000/Month"}
          </h3>
          <Button type="secondary">Book a demo</Button>
        </div>
      </div>
    </section>
  );
}

export default MainBlock;
