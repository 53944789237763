import style from "./styles.module.scss";
import Empowering from "../../../../assets/images/Empowering.svg";

function PremiumMarketingBlock() {
  return (
    <section className={style.container}>
      <div className={style.block_image}>
        <img src={Empowering} alt="Empowering" />
      </div>
      <h2 className={style.title}>{"Empowering\nFinancial Mastery"}</h2>

      <h3 className={style.description}>
        {
          "In the digital era, financial acumen is more than number-crunching;\nit's about strategic foresight and precision. At Neural Nexus,\nwe blend financial expertise with technological prowess to\nunlock your business’s economic potential."
        }
      </h3>
    </section>
  );
}

export default PremiumMarketingBlock;
