import style from "./styles.module.scss";
import Videobackground7 from "../../../../assets/video/Videobackground_7.mp4";
import Button from "../../../button";

function ElevateBusinessBlock() {
  return (
    <section className={style.block_video}>
      <video width="100%" className="video_block" autoPlay loop muted>
        <source src={Videobackground7} type="video/mp4" />
      </video>
      <div className={style.block_info}>
        <h2 className={style.title}>
          {"ELEVATE YOUR BUSINESS\nWITH NEURAL NEXUS"}
        </h2>
        <h3 className={style.description}>
          {
            "Step into a partnership with Neural Nexus and transform your financial strategy into a \ncompetitive advantage. It's time to elevate your business with the power of financial\nclarity and control."
          }
        </h3>
        <Button>Apply online</Button>
        <div className={style.block_position}>
          <h3>EXPLORE OUR SERVICES</h3>
          <div className={style.delimeter}></div>
          <h3>BOOK A STRATEGY SESSION</h3>
          <div className={style.delimeter}></div>
          <h3>LEARN MORE</h3>
        </div>
      </div>
    </section>
  );
}

export default ElevateBusinessBlock;
