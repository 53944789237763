import style from "./styles.module.scss";
import InfoBlock from "./InfoBlock/InfoBlock";
import PrivacyPolycy from "../../../../assets/images/PrivacyPolycy.svg";
import TermsAndConditions from "../../../../assets/images/TermsAndConditions.svg";
import RegulatoryCompliance from "../../../../assets/images/RegulatoryCompliance.svg";
import StrategicBusinessAudits from "../../../../assets/images/StrategicBusinessAudits.svg";

function ApprochBlock() {
  return (
    <section className={style.container}>
      <h2 className={style.title}>OUR APPROACH</h2>
      <div className={style.blocks_description}>
        <InfoBlock
          title={"Privacy Policy\nDevelopment:"}
          description="Crafting detailed privacy policies that comply with global data protection regulations"
        >
          <img src={PrivacyPolycy} alt="PrivacyPolycy" />
        </InfoBlock>
        <InfoBlock
          title={"Terms & Conditions\nAssistance"}
          description="Creating transparent terms and conditions to safeguard your business activities and clarify customer interactions"
        >
          <img src={TermsAndConditions} alt="TermsAndConditions" />
        </InfoBlock>
        <InfoBlock
          title={"Regulatory\nCompliance"}
          description="Ensuring your business adheres to the latest industry standards and legal requirements"
        >
          <img src={RegulatoryCompliance} alt="RegulatoryCompliance" />
        </InfoBlock>
        <InfoBlock
          title={"Strategic Business\nAudits:"}
          description="Comprehensive evaluations to identify areas of improvement and innovation within your business operations"
        >
          <img src={StrategicBusinessAudits} alt="StrategicBusinessAudits" />
        </InfoBlock>
      </div>
    </section>
  );
}

export default ApprochBlock;
